import { API } from 'aws-amplify'
import ability from '../../../libs/acl/ability'

export default {
  abilityRequired: 'submissions',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addItem(ctx, theData) {
      return new Promise((resolve, reject) => {
        console.log('Add Item')
        if (ability.can('create', 'submissions')) {
          console.log('Can Create')
          const apiName = 'APIGateway'

          API.post(apiName, '/company/submission', { body: theData })
            .then(response => {
              console.log('response')
              console.log('response')
              resolve(response)
            })
            .catch(error => {
              console.log('Error No current user')
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          console.error('Not Allowed')
          reject()
        }
      })
    },
  },
}
