<template>
  <div>
    <!-- Breadcrumb -->
    <b-row
      v-if="itemData"
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Submissions
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/apps/reports/type">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                  Send Submission
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-card v-if="($can('read', abilityRequired) || $can('create', abilityRequired))">
      <b-card-body>
        <!-- Input Fields -->
        <validation-observer
          ref="observer"
          #default="{ handleSubmit }"
        >
          <b-form
            @submit.prevent="handleSubmit(validateForm)"
          >
            <!-- Field: submission_type -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="12"
              >
                <b-form-group
                  label="Submission Type "
                  label-for="submission_type"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="submission_type"
                  >
                    <v-select
                      v-model="itemData.submission_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="submissionTypeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="submission_type"
                      rules="required"
                      @input="handleSelectType"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-if="showPrivacyMessage"
              class="mb-2"
            >
              <b-col
                md="12"
              >
                <div
                  id="noNameSubmit"
                  class="alert alert-info mt-0 pt-1 pb-1 pl-1 pr-1"
                  role="alert"
                >
                  <h4>Please Note:</h4>
                  This submission will be anonymous and will not be saved in this system.<br>
                  This submission will be send anonymously via email to the appropriate authority and will be dealt with in a sensitive manner.
                </div>
              </b-col>
            </b-row>
            <!-- Field: Message -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="12"
              >
                <b-form-group
                  label="Message "
                  label-for="message"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="message"
                    rules="required"
                  >
                    <b-form-textarea
                      id="message"
                      v-model="itemData.message"
                      rows="3"
                      max-rows="8"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Submit Button -->
            <div>
              <b-row class="mt-3">
                <b-col
                  v-if="$can('create', abilityRequired)"
                  md="3"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    type="submit"
                    :disabled="preventDoubleClick"
                  >
                    Submit
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { Storage, Auth } from 'aws-amplify'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { required } from '@validations'
import {
  BBreadcrumb, BBreadcrumbItem, BCard, BCardBody, BButton, BRow, BCol, BFormGroup, BForm, BFormTextarea,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import storeModule from '../storeModule'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      preventDoubleClick: false,
      itemData: {},
    }
  },
  setup() {
    const abilityRequired = 'submissions'
    const storeModuleName = 'app-submissions'
    const itemTypeName = 'Submissions'
    const allowPrinting = false
    const allowApproval = false
    const maxFiles = 1 // Use 0 for no files otherwise specify max number
    const supportedFileTypes = ['*'] // Use * for all types, otherwise use an array of file extensions
    const maxImages = 0 // Use 0 for no images otherwise specify max number
    const supportedImageTypes = ['jpg']
    const showPrivacyMessage = ref(false)

    const submissionTypeOptions = store.state.appSettings.submission_types

    // Register module
    if (!store.hasModule(storeModuleName)) store.registerModule(storeModuleName, storeModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(storeModuleName)) store.unregisterModule(storeModuleName)
    })

    return {
      abilityRequired,
      storeModuleName,
      itemTypeName,
      allowPrinting,
      allowApproval,
      maxFiles,
      supportedFileTypes,
      maxImages,
      supportedImageTypes,
      submissionTypeOptions,
      showPrivacyMessage,
    }
  },
  methods: {
    handleSelectType() {
      console.log('handleSelectType')
      if (
        this.itemData.submission_type === 'Sexual Harassment Report'
        || this.itemData.submission_type === 'Harassment Report'
        || this.itemData.submission_type === 'Violence in the Workplace Report'
        || this.itemData.submission_type === 'Theft Report'
      ) {
        console.log('handleSelectType TRUE')
        this.showPrivacyMessage = true
      } else {
        console.log('handleSelectType FALSE')
        this.showPrivacyMessage = false
      }
      console.log(this.showPrivacyMessage)
    },
    validateForm() {
      console.log('validateForm')
      this.$refs.observer.validate().then(success => {
        if (success) {
          this.preventDoubleClick = true
          console.log('form validated')
          console.log(this.itemData)
          // Create a clean dataset with only the data being updated
          const cleanData = JSON.parse(JSON.stringify(this.itemData))
          // delete cleanData.abilities
          console.log(cleanData)
          // Call backend
          store.dispatch('app-submissions/addItem', cleanData)
            .then(response => {
              // this.preventDoubleClick = false
              console.log(response)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Submission Sent',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$bvModal
                .msgBoxConfirm('Submission has been sent.', {
                  title: 'Sent',
                  size: 'sm',
                  okVariant: 'primary',
                  okTitle: 'Ok',
                  hideHeaderClose: true,
                  centered: true,
                })
                .then(value => {
                  if (value) {
                    // Reset form when OK is clicked
                    this.itemData = {
                      submission_type: null,
                      message: '',
                    }
                    this.showPrivacyMessage = false
                    this.$refs.observer.reset()
                    this.preventDoubleClick = false
                  }
                })
            })
            .catch(error => {
              console.log(error)
              this.preventDoubleClick = false
              let theErrorStr = 'The following error(s) where received:\n'
              if (typeof error.response.data.errors !== 'undefined') {
                error.response.data.errors.forEach(item => {
                  theErrorStr += `${item.message}\n`
                })
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error sending Submission.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: theErrorStr,
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
#fileInput {
  display: none;
}
.radio-spacing .custom-control {
    margin-top: 10px; /* Adjust this value to increase or decrease spacing */
}
</style>
